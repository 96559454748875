* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
::-webkit-scrollbar {
  height: 1rem;
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: hsla(0, 0%, 89%, 0.8);
  border-radius: 9999px;
  border-width: 1px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}
