.containerStyle {
  max-width: 1200px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: auto;
  padding: 10px 0;
}
.contentWrapperStyle {
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}
.positionStyle {
  width: 100%;
  padding: 10px;
  background-color: #dedede;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tableStyle {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tdStyle,
.thStyle {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}
.linkStyle {
  display: flex;
  align-self: flex-end;
  cursor: pointer;
  color: #006cff;
}

.loaderStyle {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.errorTextStyle {
  color: red;
}

.inputWrapperStyle {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  gap: 10px;
  background-color: rgb(244 244 244);
  border-radius: 15px;
}
.btnWrapperStyle {
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.textAreaStyle {
  width: 100%;
  height: auto;
  border: none;
  outline: none;
  resize: none;
  background-color: transparent;
}
